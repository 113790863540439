export enum RoleEnum {
  APPLICANT = "APPLICANT",
  COMPANY = "COMPANY",
}

export enum SectorEnum {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  BOTH = "BOTH",
}

export enum LanguageLevel {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  NATIVE = "NATIVE",
}

export enum FileType {
  PROFILE_PICTURE = "PROFILE_PICTURE",
  CV = "CV",
  COMPANY_IMAGES = "COMPANY_IMAGES",
  COMPANY_LOGO = "COMPANY_LOGO",
}

export enum Breakpoint {
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
  XXL = "XXL",
}

export enum OfferStatusEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  // SUBMITTED = "SUBMITTED",
  ARCHIVED = "ARCHIVED",
  // ALL = "ALL",
}

export enum ApplicationStatusEnum {
  ASSIGNED = "ASSIGNED",
  TO_TREAT = "TO_TREAT",
  TO_MEET = "TO_MEET",
  IN_PROGRESS = "IN_PROGRESS",
  DECLINED = "DECLINED",
  REFUSED = "REFUSED",
  RECRUITED = "RECRUITED",
}

export enum Privilege {
  JOBSLOT = "JOBSLOT",
  ENTREPRISE = "ENTREPRISE",
  CVTHEQUE = "CVTHEQUE",
}

export enum SubscriptionStatusEnum {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
}

export enum SubscriptionTypeEnum {
  JOBSLOT = "JOBSLOT",
  ENTREPRISE = "ENTREPRISE",
  CVTHEQUE_YEARLY = "CVTHEQUE_YEARLY",
  CVTHEQUE_MONTHLY = "CVTHEQUE_MONTHLY",
}

export enum DemandStatusEnum {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
}

export enum SalaryRateEnum {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
}

export enum JobTitleLevelEnum {
  NO_EXPERIENCE = "NO_EXPERIENCE",
  LOW_EXPERIENCE = "LOW_EXPERIENCE",
  MEDIUM_EXPERIENCE = "MEDIUM_EXPERIENCE",
  HIGH_EXPERIENCE = "HIGH_EXPERIENCE",
  EXPERT_EXPERIENCE = "EXPERT_EXPERIENCE",
}
