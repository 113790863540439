import axios from "axios";

export function setToken() {
  const idToken: string | null =
    typeof window !== "undefined" ? localStorage.getItem("IdToken") : null;
  if (idToken) axios.defaults.headers.common.Authorization = idToken;
}

export function storeAuthResult(
  accessToken: string | undefined,
  idToken: string | undefined,
  refreshToken: string | undefined
) {
  if (accessToken) localStorage.setItem("AccessToken", accessToken);
  if (idToken) localStorage.setItem("IdToken", idToken);
  if (!!refreshToken) localStorage.setItem("RefreshToken", refreshToken);
  setToken();
}

export function removeAuth() {
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("IdToken");
  setToken();
}
