import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Application } from "../../types/application.type";
import { ApplicationStatusEnum } from "../../types/enum.types";
import {
  KanbanData,
  KanbanDataType,
  KanbanResultType,
} from "../../types/kanban.type";
import { applicationStatus } from "../../utils/constants";

export type CompanyApplicationsState = {
  applications: KanbanDataType[];
  loading: boolean;
  error?: unknown;
};

const initialState: CompanyApplicationsState = {
  applications: [],
  loading: false,
};

const CompanyApplicationsSlice = createSlice({
  name: "Applications",
  initialState,
  reducers: {
    fetCompanyApplicationsRequest: (state) => {
      state.loading = true;
    },
    fetCompanyApplicationsFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchCompanyApplications: (state, action: PayloadAction<Application[]>) => {
      // initialize tha kanban board
      state.applications = Object.keys(applicationStatus).map((key) => ({
        title:
          applicationStatus[key as keyof typeof ApplicationStatusEnum].label,
        status:
          ApplicationStatusEnum[key as keyof typeof ApplicationStatusEnum],
        data: [],
      }));

      // fill the kanban board with the applications with the corrresponding status
      action.payload.forEach((application) => {
        const status = applicationStatus[application.status];
        const kanbanData = state.applications.find(
          (kanbanData) => kanbanData.title === status.label
        );
        if (kanbanData && application.profile.id) {
          kanbanData.data.push({
            id: application.id,
            firstname: application.profile.firstname,
            lastname: application.profile.lastname,
            image: application.profile.profileImage,
            email: application.profile?.email,
            phoneNumber: application.profile?.phoneNumber,
            order: application.order,
            offerId: application.offer.id,
            profileId: application.profile.id,
            comment: application.comment,
          });
        }
      });

      state.loading = false;
    },

    getKanbanData: (state, action: PayloadAction<KanbanDataType[]>) => {
      state.applications = action.payload;
    },

    updateSInd: (
      state,
      action: PayloadAction<{ index: number; items: KanbanData[] }>
    ) => {
      state.applications[action.payload.index].data = action.payload.items;
    },

    moveItemSameSection: (
      state,
      action: PayloadAction<{ index: number; items: KanbanData[] }>
    ) => {
      state.applications[action.payload.index].data = action.payload.items;
    },

    moveItemDifferentSection: (
      state,
      action: PayloadAction<{
        sInd: number;
        dInd: number;
        result: KanbanResultType;
      }>
    ) => {
      const sInd = action.payload.sInd;
      const dInd = action.payload.dInd;
      const result = action.payload.result;

      state.applications[sInd].data = result[sInd];
      state.applications[dInd].data = result[dInd];
    },

    reorderSections: (state) => {
      for (let i = 0; i < state.applications.length; i++) {
        let index = state.applications[i].data.length;
        state.applications[i].data.map((item) => {
          item.order = index--;
        });
      }
    },
  },
});

export const CompanyApplicationsActions = CompanyApplicationsSlice.actions;
export default CompanyApplicationsSlice;
