import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Company,
  CompanyFilters,
  GetCompanies,
} from "../../types/company.type";

export type CompaniesState = {
  companies: Company[];
  filters: CompanyFilters;
  currentPage?: number;
  total: number;
  loading: boolean;
  error?: unknown;
  applyingFilters: boolean;
};

const initialState: CompaniesState = {
  companies: [],
  filters: {},
  total: 0,
  loading: true,
  applyingFilters: false,
};

const CompaniesSlice = createSlice({
  name: "Companies",
  initialState,
  reducers: {
    fetchCompaniesRequest: (state) => {
      state.loading = true;
    },
    fetchCompaniesFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchCompanies: (state, action: PayloadAction<GetCompanies>) => {
      const { data, total } = action.payload;
      state.companies = data;
      state.total = total;
      state.loading = false;
    },
    addFilters: (state, action: PayloadAction<CompanyFilters>) => {
      state.filters = action.payload;
      state.companies = [];
    },
    resetCompanies: (state) => {
      state.companies = [];
    },
    setApplyingFilters: (state, action: PayloadAction<boolean>) => {
      state.applyingFilters = action.payload;
    },
    initializeFilters: (state) => {
      state.filters = {
        localizations: [],
        sectors: [],
        name: "",
      };
    },
  },
});

export const CompaniesActions = CompaniesSlice.actions;
export default CompaniesSlice;
