import { TokenResponse } from "../types/auth.type";
import axios from "./axios.setup";
import { removeAuth, setToken } from "./utils/token.utils";

export async function refreshToken() {
  const RefreshToken: string | null = localStorage.getItem("RefreshToken");
  if (!RefreshToken) return false;
  try {
    const res = await axios.post<
      { RefreshToken: string },
      { data: TokenResponse<"refresh"> }
    >("/auth/refreshToken", { RefreshToken });
    const { IdToken, AccessToken } = res.data.AuthenticationResult;
    localStorage.setItem("IdToken", IdToken);
    localStorage.setItem("AccessToken", AccessToken);
    setToken();
    return IdToken;
  } catch (error) {
    removeAuth();
    return null;
  }
}
