import gql from "../../apollo.setup";

export const GET_USER_INFO = gql`
  query {
    getUserInfo {
      id
      email
      confirmed
      verified
      role
      subscribedToNewsletter

      profile {
        id
        firstname
        lastname
        phoneNumber
        birthdate
        visible
        contractTypes
        hasNoExperience
        languages {
          id
          name
        }
        jobTimes
        minSalary
        maxSalary
        degree
        yearsOfExperience
        sector
        linkedin
        profileImage
        cv
        localizations {
          id
          city
          country
          longitude
          latitude
          distance
        }
        positions {
          id
          name
          level
        }
        oldPosition {
          id
          name
          level
        }
        hasUnreadNotifications
      }

      company {
        id
        slug
        name
        logoPath
        website
        facebook
        hasInActiveJobSlots
        values
        twitter
        youtube
        linkedin
        instagram
        coverImagePath
        hasJobSlot
        privileges
        companyStatus
        offices {
          city
          country
          longitude
          latitude
          label
        }
        paymentMethods {
          id
          expirationDate
          paymentMethodType
          last4Digits
          stripePaymentMethodId
          stripeCardStatus
        }
        activePaymentMethod {
          id
          expirationDate
          paymentMethodType
          last4Digits
          stripePaymentMethodId
          stripeCardStatus
        }

        grouping {
          id
          name
          picture
          promoCode
          companyCode
          percentage
        }

        mainGrouping {
          id
          name
          picture
          promoCode
          companyCode
          percentage
        }

        sectors {
          id
          name
        }
        pictures {
          id
          path
        }
        keyFigures {
          id
          key
          value
        }
        presentation
        emailTemplates {
          id
          body
          status
        }
      }
    }
  }
`;

export const GET_USER_OFFER_CONFIRMATION = gql`
  query {
    getUserInfo {
      company {
        activePaymentMethod {
          id
          expirationDate
          paymentMethodType
          last4Digits
          stripePaymentMethodId
        }
        hasJobSlot
      }
    }
  }
`;

export const TOGGLE_VISIBILITY = gql`
  mutation {
    toggleVisibility {
      id
      visible
    }
  }
`;

export const TOGGLE_NEWSLETTER = gql`
  mutation toggleNewsLetter(
    $SubscribedToNewsLetterInput: SubscribedToNewsLetterInput!
  ) {
    toggleNewsLetter(SubscribedToNewsLetterInput: $SubscribedToNewsLetterInput)
  }
`;

export const SAVE_FILTER = gql`
  mutation AddSearchHistory($SearchHistoryInput: SearchHistoryInput!) {
    addSearchHistory(SearchHistoryInput: $SearchHistoryInput) {
      id
      name
    }
  }
`;

export const GET_SAVED_FILTERS = gql`
  query {
    getSearchHistories {
      id
      createdAt
      updatedAt
      startingDate
      name
      title
      cities
      languages {
        id
        name
      }
      minSalary
      maxSalary
      contractTypes
      positions {
        id
        name
      }
      sectors {
        id
        name
      }
      experiences
      newResults
      allowNotification
    }
  }
`;
export const GET_COMPANY_SAVED_FILTERS = gql`
  query {
    getSearchHistories {
      id
      createdAt
      updatedAt
      name
      title
      cities
      languagesName
      minSalary
      maxSalary
      contractTypes
      experiences
      newResults
      allowNotification
      positions {
        id
        name
        level
      }
    }
  }
`;

export const DELETE_SAVED_FILTER = gql`
  mutation DeleteFilter($GetUniqueInput: GetUniqueInput!) {
    deleteFilter(GetUniqueInput: $GetUniqueInput)
  }
`;

export const TOGGLE_ALLOW_NOTIFICATION = gql`
  mutation ToggleAlllowNotification($GetUniqueInput: GetUniqueInput!) {
    toggleAllowNotification(GetUniqueInput: $GetUniqueInput)
  }
`;

export const GET_NUMBER_OF_NEW_RESULTS = gql`
  query {
    getNumberOfNewRequests
  }
`;

export const GET_NUMBER_OF_JOB_SLOTS = gql`
  query {
    getNumberOfJobSlots {
      active
      total
    }
  }
`;

export const HAS_ACTIVE_JOBSLOT = gql`
  query {
    hasActiveJobSlot
  }
`;
