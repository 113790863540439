import { configureStore } from "@reduxjs/toolkit";

import ApplicationsSlice, {
  ApplicationsState,
} from "./applications/application.slice";
import CandidaturesSlice, {
  CandidaturesState,
} from "./candidatures/candidatures.slice";
import CompaniesSlice, { CompaniesState } from "./companies/companies.slice";
import CompanyApplicationsSlice, {
  CompanyApplicationsState,
} from "./companyApplications/companyApplications.slice";
import CompanyOffersSlice, {
  CompanyOffersState,
} from "./companyOffers/companyOffers.slice";
import FavoritesSlice, { FavoritesState } from "./favorites/favorites.slice";
import NotificationsSlice, {
  NotificationsState,
} from "./notifications/notifications.slice";
import OffersSlice, { OffersState } from "./offers/offers.slice";
import OfferSlice, { OfferState } from "./singleOffer/singleOffer.slice";

// initial state defined with redux store as default value
const initialState = {};

export interface StoreState {
  offers: OffersState;
  offer: OfferState;
  favorites: FavoritesState;
  applications: ApplicationsState;
  companies: CompaniesState;
  companyApplications: CompanyApplicationsState;
  candidatures: CandidaturesState;
  companyOffers: CompanyOffersState;
  notifications: NotificationsState;
}

// all reducers
const combineReducers = {
  offers: OffersSlice.reducer,
  offer: OfferSlice.reducer,
  favorites: FavoritesSlice.reducer,
  applications: ApplicationsSlice.reducer,
  companies: CompaniesSlice.reducer,
  companyApplications: CompanyApplicationsSlice.reducer,
  candidatures: CandidaturesSlice.reducer,
  companyOffers: CompanyOffersSlice.reducer,
  notifications: NotificationsSlice.reducer,
};

export const Store = configureStore({
  reducer: combineReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: initialState,
});

export type Dispatch = typeof Store.dispatch;
