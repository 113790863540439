import { UserContextData } from "../types/user.types";

export const LanguageLevels = {
  BEGINNER: "Débutant",
  INTERMEDIATE: "Intermédiaire",
  ADVANCED: "Courant",
  NATIVE: "Bilingue",
};

export const NUMBER_OF_ITEMS_PER_PAGINATION = 10;

export const profileImageExtenstions = ["png", "jpg", "jpeg", "jfif", "webp"];
export const cvExtenstions = ["pdf"];

export const allLanguages = [
  "Anglais",
  "Français",
  "Allemand",
  "Espagnol",
  "Italien",
  "Portugais",
  "Russe",
  "Japonais",
  "Chinois",
  "Arabe",
  "Indien",
  "Turc",
  "Indonésien",
  "Thaïlandais",
  "Vietnamien",
  "Coréen",
  "Polonais",
  "Tchèque",
  "Danois",
  "Norvégien",
  "Suédois",
];

export const breakpoints = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 3000,
};

export const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export const applicationStatus = {
  ASSIGNED: {
    label: "Profils contactés",
    color: "green",
  },
  TO_TREAT: {
    label: "À traiter",
    color: "#FFc400",
  },
  TO_MEET: {
    label: "À rencontrer",
    color: "#000066",
  },
  IN_PROGRESS: {
    label: "Entretien en cours",
    color: "#0000FF",
  },
  DECLINED: {
    label: "Désistement",
    color: "var(--color-dark-1)",
  },
  REFUSED: {
    label: "Refusé",
    color: "#FF0000",
  },
  RECRUITED: {
    label: "Recruté",
    color: "#00FF00",
  },
};

export const jobSlotPrice = 99;
export const entreprisePrice = 299;
export const cvThequeMonthlyPrice = 249;
export const cvThequeYearlyPrice = 199;

export const formatPrice = (price: number, percentage?: number) => {
  if (!percentage) return price;
  const discount = (price * percentage) / 100;
  return +(price - discount).toFixed(2);
};

export const getCompanyDiscount = (user?: UserContextData) =>
  user?.company?.grouping?.percentage ||
  user?.company?.mainGrouping?.percentage;
