import { useLazyQuery } from "@apollo/client";
import { createContext, FC, useEffect, useState } from "react";

import { GET_USER_INFO } from "../api/graphql/user";
import { removeAuth } from "../api/utils/token.utils";
import { GetUserInfoResponse, UserContextData } from "../types/user.types";

interface ContextData {
  isAuthenticated?: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  user?: UserContextData;
  setUser: React.Dispatch<React.SetStateAction<UserContextData | undefined>>;
  invalidateUser: () => void;
}

const initialState: ContextData = {
  setUser: () => {},
  setIsAuthenticated: () => {},
  invalidateUser: () => {},
};

const AuthContext = createContext(initialState);

type Props = {
  children?: React.ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<UserContextData>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const [getUserTrigger] = useLazyQuery<GetUserInfoResponse>(GET_USER_INFO);

  const initializeUser = async () => {
    const { error, data } = await getUserTrigger();

    if (error) {
      removeAuth();
      setIsAuthenticated(false);
    } else {
      const userData = data?.getUserInfo;
      if (userData) {
        setUser(userData);
        setIsAuthenticated(true);
      } else setIsAuthenticated(false);
    }
  };

  const invalidateUser = async () => {
    const { error, data } = await getUserTrigger();

    if (error) {
      removeAuth();
      setIsAuthenticated(false);
    } else {
      const userData = data?.getUserInfo;
      if (userData) {
        setUser(userData);
        setIsAuthenticated(true);
      } else setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    initializeUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
        invalidateUser: () => {
          invalidateUser();
        },
      }}
    >
      <div>{children}</div>
    </AuthContext.Provider>
  );
};

export default AuthContext;
