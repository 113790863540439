import "../styles/globals.css";

import { ApolloProvider, useLazyQuery } from "@apollo/client";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import styled from "styled-components";

import { apolloClient } from "../api/apollo.setup";
import { GET_REQUESTS_COUNT } from "../api/graphql/profile";
import { AuthProvider } from "../contexts/AuthContext";
import useAuth from "../hooks/useAuth";
import { Store } from "../redux/store";
import { RoleEnum } from "../types/enum.types";

const PageWrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor ?? "inherits"};
`;
interface PageProps {
  backgroundColor?: string;
}

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [firstVisit, setFirstVisit] = useState(false);
  const [getRequestsCountRequest] = useLazyQuery<
    { getRequestsCount: number },
    never
  >(GET_REQUESTS_COUNT);

  useEffect(() => {
    if (!firstVisit) {
      if (user?.role === RoleEnum.APPLICANT) {
        getRequestsCountRequest().then((res) => {
          if (res.data && res.data?.getRequestsCount > 0) setFirstVisit(true);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    document.body.classList.remove("company-variables");
    document.body.classList.remove("applicant-variables");

    if (user) {
      document.body.classList.add(
        `${
          user.role === RoleEnum.COMPANY
            ? "company-variables"
            : "applicant-variables"
        }`
      );
    } else {
      document.body.classList.add("applicant-variables");
    }
  });
  return <div>{children}</div>;
};

function MyApp({ Component, pageProps }: AppProps) {
  const props: PageProps = pageProps as PageProps;
  const displayHubspot = process.env.NEXT_PUBLIC_ENVIRONMENT === "production";

  useEffect(() => {
    if (window && process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="google-site-verification"
          content="GUL0KzKW3zSfsHiYZr6MpQyHLnhaAkYxfoyxQelOVow"
        />
      </Head>
      <Provider store={Store}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <PageWrapper backgroundColor={props.backgroundColor} lang="fr">
              <Wrapper>
                <Component {...pageProps} />
              </Wrapper>
            </PageWrapper>
          </AuthProvider>
        </ApolloProvider>
      </Provider>
      {displayHubspot && (
        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/139821865.js"
        />
      )}
    </>
  );
}

export default MyApp;
