import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetCompanyOffersCount } from "../../types/company.type";
import { OfferStatusEnum } from "../../types/enum.types";
import {
  GetCompanyOffers,
  GetOffers,
  ItemCount,
  Offer,
} from "../../types/offer.type";
import { FilterOfferMapping } from "../../utils/mapping";

export type CompanyOffersState = {
  offers: Offer[];
  fetchMore: boolean;
  cursor?: number;
  total: number;
  loading: boolean;
  error?: unknown;
  items: ItemCount<OfferStatusEnum>[];
};

const initialState: CompanyOffersState = {
  offers: [],
  total: 0,
  fetchMore: true,
  loading: false,
  items: [
    {
      label: FilterOfferMapping[OfferStatusEnum.PUBLISHED],
      value: OfferStatusEnum.PUBLISHED,
      count: 0,
    },
    {
      label: FilterOfferMapping[OfferStatusEnum.DRAFT],
      value: OfferStatusEnum.DRAFT,
      count: 0,
    },
    {
      label: FilterOfferMapping[OfferStatusEnum.ARCHIVED],
      value: OfferStatusEnum.ARCHIVED,
      count: 0,
    },
    // {
    //   label: FilterOfferMapping[OfferStatusEnum.SUBMITTED],
    //   value: OfferStatusEnum.SUBMITTED,
    //   count: 0,
    // },
  ],
};

const CompanyOffersSlice = createSlice({
  name: "CompanyOffers",
  initialState,
  reducers: {
    fetchCompanyOffersRequest: (state) => {
      state.loading = true;
    },
    fetchCompanyOffersFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchCompanySlugOffers: (state, action: PayloadAction<GetOffers>) => {
      const { data, total } = action.payload;
      if (state.fetchMore) state.offers = [...state.offers, ...data];
      state.total = total;
      state.loading = false;
    },
    fetchCompanyOffers: (state, action: PayloadAction<GetCompanyOffers>) => {
      const { data, fetchMore, cursor } = action.payload;
      state.offers = [...state.offers, ...data];
      state.cursor = cursor;
      state.fetchMore = fetchMore;
      state.loading = false;
    },
    resetCompanyOffers: (state) => {
      state.offers = [];
      state.cursor = undefined;
      state.fetchMore = true;
      state.loading = false;
    },
    resetOffersCount: (state) => {
      state.items = [
        {
          label: FilterOfferMapping[OfferStatusEnum.PUBLISHED],
          value: OfferStatusEnum.PUBLISHED,
          count: 0,
        },
        {
          label: FilterOfferMapping[OfferStatusEnum.DRAFT],
          value: OfferStatusEnum.DRAFT,
          count: 0,
        },
        {
          label: FilterOfferMapping[OfferStatusEnum.ARCHIVED],
          value: OfferStatusEnum.ARCHIVED,
          count: 0,
        },
        // {
        //   label: FilterOfferMapping[OfferStatusEnum.SUBMITTED],
        //   value: OfferStatusEnum.SUBMITTED,
        //   count: 0,
        // },
      ];
    },
    fetchOffersCount: (
      state,
      action: PayloadAction<GetCompanyOffersCount[]>
    ) => {
      const { items } = state;
      const { payload } = action;

      items.forEach((item) => {
        const count = payload.find((c) => c.value === item.value);
        if (count) item.count = count.count;
      });
    },
    changeStatusOffer: (
      state,
      action: PayloadAction<{ id: number; status: OfferStatusEnum }>
    ) => {
      const { id, status } = action.payload;
      const offer = state.offers.find((offer) => offer.id === id);

      const items = state.items;
      const oldStatus = offer?.status;
      const oldItem = items.find((item) => item.value === oldStatus);
      if (oldItem) oldItem.count = oldItem.count - 1;

      if (offer) offer.status = status;
      const item = items.find((item) => item.value === status);
      if (item) item.count = item.count + 1;

      const index = state.offers.findIndex((offer) => offer.id === id);
      if (index !== -1) state.offers.splice(index, 1);
    },
  },
});

export const CompanyOffersActions = CompanyOffersSlice.actions;
export default CompanyOffersSlice;
