import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OfferStatusEnum } from "../../types/enum.types";
import { GetOffers, Offer, OfferFilters } from "../../types/offer.type";
import { SortStrategiesKeys } from "../../types/select.type";

export type OffersState = {
  offers: Offer[];
  currentPage: number;
  total: number;
  consulted: Offer[];
  filters: OfferFilters;
  loadingConsulted: boolean;
  loadingOffers: boolean;
  error?: unknown;
  sortStrategy: SortStrategiesKeys;
};

const initialState: OffersState = {
  offers: [],
  total: 0,
  consulted: [],
  currentPage: 0,
  filters: {
    cities: [],
    contractTypes: [],
    languages: [],
    sectors: [],
    sort: "RECENT",
  },
  loadingConsulted: false,
  loadingOffers: true,
  error: undefined,
  sortStrategy: "RECENT",
};

const OffersSlice = createSlice({
  name: "Offers",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingOffers = action.payload;
    },
    fetchConsultedOffersRequest: (state) => {
      state.loadingConsulted = true;
    },
    fetchOffersFails: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchOffersData: (state, action: PayloadAction<GetOffers>) => {
      const { data, currentPage, total } = action.payload;
      state.offers = data;
      state.currentPage = currentPage;
      state.total = total;
      state.loadingOffers = false;
    },
    fetchSortedScoredOffers: (state, action: PayloadAction<Offer[]>) => {
      state.offers = action.payload;
      state.loadingOffers = false;
    },
    addFilters: (state, action: PayloadAction<OfferFilters>) => {
      state.filters = action.payload;
      state.currentPage = 0;
    },
    fetchConsultedOffers: (state, action: PayloadAction<Offer[]>) => {
      state.consulted = action.payload;
      state.loadingConsulted = false;
    },
    resetOffers: (state) => {
      state.offers = [];
      state.currentPage = 0;
    },
    resetFilters: (state) => {
      state.currentPage = 0;
      state.filters = {
        cities: [],
        contractTypes: [],
        languages: [],
        sectors: [],
        sort: "RECENT",
        status: OfferStatusEnum.PUBLISHED,
      };
    },

    emptyFilters: (state) => {
      state.currentPage = 0;
      state.filters = {
        cities: [],
        contractTypes: [],
        languages: [],
        sectors: [],
        sort: "RECENT",
        status: OfferStatusEnum.PUBLISHED,
      };
    },
    setSortStrategy: (state, action: PayloadAction<SortStrategiesKeys>) => {
      state.sortStrategy = action.payload;
    },
  },
});

export const OffersActions = OffersSlice.actions;
export default OffersSlice;
