import {
  JobTitleLevelEnum,
  OfferStatusEnum,
  SalaryRateEnum,
  SubscriptionTypeEnum,
} from "../types/enum.types";
import {
  ContractType,
  Degree,
  ExperienceYears,
  JobTimes,
  ProfilesSortStrategy,
  SortStrategiesKeys,
} from "../types/select.type";
import {
  cvThequeMonthlyPrice,
  cvThequeYearlyPrice,
  entreprisePrice,
  formatPrice,
  jobSlotPrice,
} from "./constants";

export const sortStrategiesKeys: Array<SortStrategiesKeys> = [
  "RECENT",
  "COMPATIBILITY",
];
export const filterOfferStatus: Array<OfferStatusEnum> = [
  // OfferStatusEnum.ALL,
  OfferStatusEnum.ARCHIVED,
  OfferStatusEnum.PUBLISHED,
  OfferStatusEnum.DRAFT,
];

export const convertStatusToString = (query: OfferStatusEnum) => {
  switch (query) {
    case "PUBLISHED":
      return "Publiée";
    case "ARCHIVED":
      return "Archivée";
    // case "SUBMITTED":
    //   return "En cours";
    case "DRAFT":
      return "Brouillon";
    default:
      return "Brouillon";
  }
};

export const FilterOfferMapping: Record<OfferStatusEnum, string> = {
  // ALL: "Tout voir",
  DRAFT: "Brouillon",
  PUBLISHED: "Publiée",
  ARCHIVED: "Archivée",
  // SUBMITTED: "En cours",
};
export const SortStrategiesMapping: Record<SortStrategiesKeys, string> = {
  RECENT: "Récents",
  COMPATIBILITY: "Compatibilité",
};

export const jobTimesKeys: Array<JobTimes> = ["FULL_TIME", "PART_TIME"];

export const JobTimesMapping: Record<JobTimes, string> = {
  FULL_TIME: "Temps plein",
  PART_TIME: "Temps partiel",
};

export const experienceYearsKeys: Array<ExperienceYears> = [
  "NO_EXPERIENCE",
  "LOW_EXPERIENCE",
  "MEDIUM_EXPERIENCE",
  "HIGH_EXPERIENCE",
  "EXPERT_EXPERIENCE",
];

export const ExperienceYearsMapping: Record<ExperienceYears, string> = {
  NO_EXPERIENCE: "Aucune expérience",
  LOW_EXPERIENCE: "1-3 ans",
  MEDIUM_EXPERIENCE: "3-6 ans",
  HIGH_EXPERIENCE: "6-10 ans",
  EXPERT_EXPERIENCE: "Plus de 10 ans",
};

export const ProfilesSortStrategyKeys: Array<ProfilesSortStrategy> = [
  "COMPATIBILITY",
  "CREATION_INCREASING",
  "CREATION_DECREASING",
];

export const profilesSortStrategyMapping: Record<ProfilesSortStrategy, string> =
  {
    COMPATIBILITY: "Compatibilité",
    CREATION_INCREASING: "Anciens",
    CREATION_DECREASING: "Récents",
  };

export const contractTypeKeys: Array<ContractType> = [
  "CDI",
  "CDD",
  "ALTERNANCE",
  "STAGE",
  "INTERIM",
  "INDEPENDANT",
  "SAISONNIER",
  "VOLUNTEER",
  "FREELANCE",
  "TRAINING",
];

export const contractTypeMapping: Record<ContractType, string> = {
  CDI: "CDI",
  CDD: "CDD",
  ALTERNANCE: "Alternance",
  STAGE: "Stage",
  INTERIM: "Intérim",
  INDEPENDANT: "Indépendant",
  SAISONNIER: "Saisonnier",
  VOLUNTEER: "Bénévolat",
  FREELANCE: "Freelance",
  TRAINING: "Apprentissage",
};

export const degreeKeys: Array<Degree> = [
  "BAC_NONE",
  "BAC_0",
  "BAC_2",
  "BAC_3",
  "BAC_5",
  "BAC_8",
];

export const degreeMapping: Record<Degree, string> = {
  BAC_NONE: "Sans diplôme",
  BAC_0: "BAC / Baccalauréat",
  BAC_2: "BAC + 2 / BTS",
  BAC_3: "BAC + 3 / Licence",
  BAC_5: "BAC + 5 / Master",
  BAC_8: "BAC + 8 / Doctorat",
};

export const positionsLevelKeys: Array<JobTitleLevelEnum> = [
  JobTitleLevelEnum.NO_EXPERIENCE,
  JobTitleLevelEnum.LOW_EXPERIENCE,
  JobTitleLevelEnum.MEDIUM_EXPERIENCE,
  JobTitleLevelEnum.HIGH_EXPERIENCE,
  JobTitleLevelEnum.EXPERT_EXPERIENCE,
];

export const positionsLevelMapping: Record<JobTitleLevelEnum, string> = {
  EXPERT_EXPERIENCE: "Expert",
  HIGH_EXPERIENCE: "Senior",
  MEDIUM_EXPERIENCE: "Confirmé",
  LOW_EXPERIENCE: "Junior",
  NO_EXPERIENCE: "Débutant",
};
export interface SubscriptionValue {
  label: string;
  value: number;
}

export const subscriptionMapping = (
  percentage?: number
): Record<SubscriptionTypeEnum, SubscriptionValue> => ({
  ENTREPRISE: {
    label: "Entreprise",
    value: formatPrice(entreprisePrice, percentage),
  },
  JOBSLOT: { label: "Jobslot", value: formatPrice(jobSlotPrice, percentage) },
  CVTHEQUE_MONTHLY: {
    label: "CVthèque mensuelle",
    value: formatPrice(cvThequeMonthlyPrice, percentage),
  },
  CVTHEQUE_YEARLY: {
    label: "CVthèque annuelle",
    value: formatPrice(cvThequeYearlyPrice, percentage),
  },
});

export const salaryRateMapping: Record<SalaryRateEnum, string> = {
  YEARLY: "brut annuel",
  MONTHLY: "brut mensuel",
  DAILY: "brut journalier",
  HOURLY: "brut horaire",
};
