import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Offer } from "../../types/offer.type";

export type OfferState = {
  offer?: Offer;
  loading: boolean;
  error?: unknown;
};

const initialState: OfferState = {
  loading: false,
};

const OfferSlice = createSlice({
  name: "Offer",
  initialState,
  reducers: {
    fetchOffersRequest: (state) => {
      state.loading = true;
    },
    fetchOffersFails: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchOneOffer: (state, action: PayloadAction<Offer>) => {
      state.offer = action.payload;
    },
  },
});

export const OfferActions = OfferSlice.actions;
export default OfferSlice;
