import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  DocumentNode,
  from,
  fromPromise,
  gql,
  InMemoryCache,
  TypedDocumentNode,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { refreshToken } from "./refreshToken";

interface PreviousContext {
  headers: { [key: string]: string };
}

export const GraphqlHttpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL + "graphql",
});

export const GraphqlDefaultOptions: DefaultOptions = {
  watchQuery: { fetchPolicy: "no-cache", errorPolicy: "ignore" },
  query: { fetchPolicy: "no-cache", errorPolicy: "ignore" },
  mutate: { fetchPolicy: "no-cache", errorPolicy: "all" },
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            const tokenRefreshPromise = refreshToken().then(() => {
              // Modify the operation context with a new token
              const idToken = localStorage.getItem("IdToken");
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                headers: { ...oldHeaders, authorization: idToken },
              });
            });
            // Retry the request, returning the new observable
            return fromPromise(tokenRefreshPromise).flatMap(() =>
              forward(operation)
            );
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }
    }
  }
);

function getAuthLink() {
  return setContext((_, { headers }: PreviousContext) => {
    // get the authentication token from local storage if it exists
    const idToken: string = localStorage.getItem("IdToken") ?? "no-token";
    // return the headers to the context so httpLink can read them
    if (!idToken) return { headers };
    return { headers: { ...headers, authorization: idToken } };
  });
}

export function initApolloClient() {
  const client = new ApolloClient({
    link: from([errorLink, getAuthLink(), GraphqlHttpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    connectToDevTools: true,
    defaultOptions: GraphqlDefaultOptions,
  });
  return client;
}

export function initStaticApolloClient() {
  const client = new ApolloClient({
    link: from([errorLink, GraphqlHttpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    connectToDevTools: true,
    defaultOptions: GraphqlDefaultOptions,
  });
  return client;
}

export const apolloClient = initApolloClient();
export const staticApolloClient = initStaticApolloClient();

/* GRAPHQL QUERY */
export async function graphqlQuery<T, TVariables>(
  query: DocumentNode | TypedDocumentNode<T, TVariables>,
  variables?: TVariables
) {
  return apolloClient.query<T, TVariables>({
    query,
    variables,
  });
}

/* GRAPHQL MUTATION */
export async function graphqlMutation<TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
) {
  return apolloClient.mutate({ mutation, variables });
}

export default gql;
