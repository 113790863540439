import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Application, GetApplications } from "../../types/application.type";
import { ApplicationStatusEnum } from "../../types/enum.types";

export type ApplicationsState = {
  applications: Application[];
  cursor?: number;
  total?: number;
  fetchMore: boolean;
  loading: boolean;
  error?: unknown;
  status?: ApplicationStatusEnum;
};

const initialState: ApplicationsState = {
  applications: [],
  fetchMore: true,
  loading: false,
  status: undefined,
};

const ApplicationsSlice = createSlice({
  name: "Applications",
  initialState,
  reducers: {
    fetchApplicationsRequest: (state) => {
      state.loading = true;
    },
    fetchApplicationsFails: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchApplications: (state, action: PayloadAction<GetApplications>) => {
      const { data, cursor, total, fetchMore } = action.payload;
      if (state.fetchMore)
        state.applications = [...state.applications, ...data];
      state.cursor = cursor;
      state.fetchMore = fetchMore;
      state.total = total;
      state.loading = false;
    },
    resetApplications: (state) => {
      state.applications = [];
      state.cursor = undefined;
      state.fetchMore = true;
      state.total = 0;
      state.status = undefined;
    },
  },
});

export const ApplicationsActions = ApplicationsSlice.actions;
export default ApplicationsSlice;
