import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Article, GetArticles } from "../../types/article.type";
import { Company, GetCompanies } from "../../types/company.type";
import { GetOffersFavorites, Offer } from "../../types/offer.type";

export type FavoritesState = {
  offerFavorites: Offer[];
  articleFavorites: Article[];
  companyFavorites: Company[];
  fetchOffers: boolean;
  fetchCompanies: boolean;
  fetchArticles: boolean;
  cursor?: number;
  loadingOffers: boolean;
  loadingCompanies: boolean;
  loadingArticles: boolean;
  offersTotal: number;
  companiesTotal: number;
  searchHistoriesTotal: number;
  articlesTotal: number;
  error?: unknown;
};

const initialState: FavoritesState = {
  offerFavorites: [],
  articleFavorites: [],
  companyFavorites: [],
  offersTotal: 0,
  companiesTotal: 0,
  articlesTotal: 0,
  searchHistoriesTotal: 0,
  fetchOffers: true,
  fetchArticles: true,
  fetchCompanies: true,
  loadingOffers: false,
  loadingCompanies: false,
  loadingArticles: false,
};

const FavoritesSlice = createSlice({
  name: "Favorites",
  initialState,
  reducers: {
    fetchFavoriteOffersRequest: (state) => {
      state.fetchOffers = true;
    },
    fetchFavoriteCompaniesRequest: (state) => {
      state.loadingCompanies = true;
    },
    fetchFavoriteArticlesRequest: (state) => {
      state.loadingArticles = true;
    },
    fetchFavoritesFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchFavoriteOffers: (state, action: PayloadAction<GetOffersFavorites>) => {
      const { data, total, cursor, fetchMore } = action.payload;
      if (state.fetchOffers)
        state.offerFavorites = [...state.offerFavorites, ...data];
      state.fetchOffers = fetchMore ?? true;
      state.cursor = cursor;
      state.offersTotal = total;
      state.loadingOffers = false;
    },
    fetchFavoriteArticles: (state, action: PayloadAction<GetArticles>) => {
      const { data, cursor, total, fetchMore } = action.payload;
      if (state.fetchArticles)
        state.articleFavorites = [...state.articleFavorites, ...data];
      state.cursor = cursor;
      state.fetchArticles = fetchMore;
      state.articlesTotal = total;
      state.loadingArticles = false;
    },
    fetchFavoriteCompanies: (state, action: PayloadAction<GetCompanies>) => {
      const { data, total } = action.payload;
      if (state.fetchCompanies)
        state.companyFavorites = [...state.companyFavorites, ...data];
      state.companiesTotal = total;
      state.loadingCompanies = false;
    },
    addOfferToFavorite: (state, action: PayloadAction<Offer>) => {
      state.offerFavorites.push(action.payload);
    },
    setTotalFavorties: (
      state,
      action: PayloadAction<{
        offers?: number;
        companies?: number;
        articles?: number;
        searchHistories?: number;
      }>
    ) => {
      state.offersTotal = action.payload.offers ?? 0;
      state.companiesTotal = action.payload.companies ?? 0;
      state.articlesTotal = action.payload.articles ?? 0;
      state.searchHistoriesTotal = action.payload.searchHistories ?? 0;
    },
    removeOfferFromFavorites: (state, action: PayloadAction<number>) => {
      state.offerFavorites = state.offerFavorites.filter(
        (offer) => offer.id !== action.payload
      );
      state.offersTotal -= 1;
    },

    removeFilterFromFavorites: (state) => {
      state.searchHistoriesTotal -= 1;
    },
    removeArticleFromFavorites: (state, action: PayloadAction<number>) => {
      state.articleFavorites = state.articleFavorites.filter(
        (article) => article.id !== action.payload
      );
      state.articlesTotal -= 1;
    },
    removeCompanyFromFavorites: (state, action: PayloadAction<number>) => {
      state.companyFavorites = state.companyFavorites.filter(
        (company) => company.id !== action.payload
      );
      state.companiesTotal -= 1;
    },
    resetFavorites: (state) => {
      state.offerFavorites = [];
      state.articleFavorites = [];
      state.companyFavorites = [];
      state.cursor = undefined;
      state.fetchCompanies = true;
      state.fetchArticles = true;
      state.fetchOffers = true;
      state.loadingArticles = false;
      state.loadingCompanies = false;
      state.loadingOffers = false;
    },
  },
});

export const FavoritesActions = FavoritesSlice.actions;
export default FavoritesSlice;
