import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetNotifications, Notification } from "../../types/notification.type";

export type NotificationsState = {
  notifications: Notification[];
  fetchMore: boolean;
  cursor?: number;
  total: number;
  loading: boolean;
  error?: unknown;
};

const initialState: NotificationsState = {
  notifications: [],
  total: 0,
  fetchMore: true,
  loading: false,
};

const NotificationsSlice = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    fetchNotificationsRequest: (state) => {
      state.loading = true;
    },
    fetchNotificationsFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchNotifications: (state, action: PayloadAction<GetNotifications>) => {
      const { data, cursor, total, fetchMore } = action.payload;
      if (state.fetchMore)
        state.notifications = [...state.notifications, ...data];
      state.cursor = cursor;
      state.fetchMore = fetchMore;
      state.total = total;
      state.loading = false;
    },

    resetNotifications: (state) => {
      state.notifications = [];
      state.cursor = undefined;
      state.fetchMore = true;
    },
  },
});

export const NotificationsActions = NotificationsSlice.actions;
export default NotificationsSlice;
