import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  GetProfilesPaginated,
  Profile,
  ProfileFilters,
} from "../../types/profile.types";

export type CandidaturesState = {
  candidatures: Profile[];
  filters: ProfileFilters;
  offerIdStatus: "exist" | "removed";
  cursor?: number;
  fetchMore: boolean;
  total: number;
  loading: boolean;
  error?: unknown;
  applyFilter: boolean;
};

const initialState: CandidaturesState = {
  candidatures: [],
  offerIdStatus: "exist",
  filters: {
    contractTypes: [],
    languages: [],
    minSalary: 30000,
    maxSalary: 80000,
    yearsOfExperience: [],
  },
  total: 0,
  fetchMore: true,
  loading: false,
  applyFilter: false,
};

const CandidaturesSlice = createSlice({
  name: "Candidatures",
  initialState,
  reducers: {
    fetchCandidaturesRequest: (state) => {
      state.loading = true;
    },
    fetchCandidaturesFail: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload;
    },
    fetchCandidaturesSuccess: (state) => {
      state.loading = false;
    },

    fetchCandidatures: (state, action: PayloadAction<GetProfilesPaginated>) => {
      const { data, cursor, total, fetchMore } = action.payload;
      if (state.fetchMore)
        state.candidatures = [...state.candidatures, ...data];
      state.cursor = cursor;
      state.fetchMore = fetchMore;
      state.total = total;
      state.loading = false;
    },
    changeOfferIdStatus: (
      state,
      action: PayloadAction<"exist" | "removed">
    ) => {
      state.offerIdStatus = action.payload;
    },
    addFilter: (state, action: PayloadAction<ProfileFilters>) => {
      state.filters = { ...state.filters, ...action.payload };
      state.candidatures = [];
      state.cursor = undefined;
      state.fetchMore = true;
      state.total = 0;
    },
    resetCandidatures: (state) => {
      state.candidatures = [];
      state.cursor = undefined;
      state.fetchMore = true;
      state.total = 0;
    },
    initializeFilters: (state) => {
      state.filters = {
        contractTypes: [],
        languages: [],
        minSalary: 30000,
        maxSalary: 80000,
        yearsOfExperience: [],
        name: "",
        localization: "",
        sort: undefined,
      };
    },
    apply: (state) => {
      state.applyFilter = true;
    },
    ignore: (state) => {
      state.applyFilter = false;
    },
  },
});

export const CandidaturesActions = CandidaturesSlice.actions;
export default CandidaturesSlice;
