import gql from "../../apollo.setup";

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($UpdateProfileInput: UpdateProfileInput!) {
    updateProfile(UpdateProfileInput: $UpdateProfileInput) {
      id
      firstname
      lastname
      phoneNumber
      birthdate
      contractTypes
      visible
      languages {
        id
        name
      }
      jobTimes
      minSalary
      maxSalary
      degree
      yearsOfExperience
      sector
      linkedin
      profileImage
      uploadedProfileUrl
      cv
      localizations {
        id
        city
        country
        longitude
        latitude
        distance
      }
      positions {
        id
        name
        level
      }

      oldPosition {
        id
        name
        level
      }
    }
  }
`;

export const GET_PROFILES = gql`
  query GetProfiles($GetProfilesInput: GetProfilesInput!) {
    getProfiles(GetProfilesInput: $GetProfilesInput) {
      data {
        id
        firstname
        lastname
        phoneNumber
        birthdate
        languages {
          id
          name
        }
        minSalary
        maxSalary
        contractTypes
        localizations {
          city
        }
        profileImage
        yearsOfExperience
        score
      }
      total
      cursor
      fetchMore
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($GetProfileInput: GetProfileInput!) {
    getProfile(GetProfileInput: $GetProfileInput) {
      id
      firstname
      lastname
      birthdate
      profileImage
      phoneNumber
      hasAccess
      isVisible
      hasNoExperience
      languages {
        name
      }
      localizations {
        city
        country
        distance
      }
      positions {
        id
        name
        level
      }
      oldPosition {
        id
        name
        level
      }
      cv
      visible
      contractTypes
      minSalary
      maxSalary
      degree
      yearsOfExperience
      sector
      user {
        email
      }
    }
  }
`;

export const GET_TOTAL_FAVORITES = gql`
  query {
    getTotalFavorites {
      offers
      companies
      articles
      searchHistories
    }
  }
`;

export const SEND_VIEW_REQUEST = gql`
  mutation SendViewRequest($SendViewRequestInput: SendViewRequestInput!) {
    sendViewRequest(SendViewRequestInput: $SendViewRequestInput) {
      id
    }
  }
`;

export const GET_REQUESTS_COUNT = gql`
  query {
    getRequestsCount
  }
`;

export const GET_REQUESTS = gql`
  query {
    getRequests {
      id
      status
      company {
        id
        name
        logoPath
      }
    }
  }
`;

export const ACCEPT_REQUEST = gql`
  mutation AcceptRequest($RequestInput: RequestInput!) {
    acceptRequest(RequestInput: $RequestInput) {
      id
    }
  }
`;

export const REJECT_REQUEST = gql`
  mutation RejectRequest($RequestInput: RequestInput!) {
    rejectRequest(RequestInput: $RequestInput) {
      id
    }
  }
`;

export const REMOVE_FILTERS_NOTIFICATIONS = gql`
  query {
    removeFiltersNotifications
  }
`;
